*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


img {
  max-width: 100%;
  object-fit: cover;
}

html,
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.checkout{
  background-color: #1d2332;
}
.main{
  background-color: #1d2332;
  color: #ffffff;
}

.separator {
  max-width: 100%;
  height: 160px;
  background: linear-gradient(180deg, #1D2332 16.15%, rgba(29, 35, 50, 0.00) 99.48%);
  z-index: 5;
  position: relative;
}

.bottom-separator {
  max-width: 100%;
  height: 190px;
  background: linear-gradient(180deg, rgba(29, 35, 50, 0.00) 0%, #1D2332 99.48%);
  z-index: 5;
  position: relative;
}

.top-page-line {
  max-width: 100%;
  height: 48px;
  background: rgba(14, 41, 60, 0.50);
  z-index: 5;
  position: relative;
}
